/* src/LoginPage.scss */
$primary-color: #3498db;
$secondary-color: #2ecc71;
$font-color: #2c3e50;

.checkin-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #f5f5f5;
}

.checkin-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.checkin-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.warning-message {
  background-color: #fff3cd;
  color: #856404;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.4;
}

.checkin-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      font-size: 16px;
      color: #555;
      font-weight: 500;
    }

    input {
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 8px;
      font-size: 16px;
      width: 100%;
      
      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }
}

.checkin-button {
  background-color: #007bff;
  color: white;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  
  &:hover {
    background-color: #0056b3;
  }
  
  &:active {
    transform: translateY(1px);
  }
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  
  h2 {
    font-size: 20px;
    margin: 0;
  }
}

.ip-address {
  text-align: center;
  color: #666;
  font-size: 12px;
  margin-top: 20px;
}

// Media queries for different screen sizes
@media (max-width: 480px) {
  .checkin-page {
    padding: 10px;
  }
  
  .checkin-container {
    padding: 16px;
  }
  
  .checkin-title {
    font-size: 20px;
  }
}

// Add iOS-specific styling
@supports (-webkit-touch-callout: none) {
  input {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .checkin-button {
    -webkit-tap-highlight-color: transparent;
  }
}
